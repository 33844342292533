import './Carousel.css';
//import React, {useState} from 'react';
import {Col, Row} from 'react-bootstrap';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
// import CarouselImg from'./images/fondoespecialistas.png'
import { ProCards } from './Card';
import { especialistasData } from '../Data/EspecialistasData';
import { CarouselServiciosData } from './CarouselServiciosData';
import imgCarousel from "./images/Ansiedad.png"
import { Carousel2Data } from '../Data/Carousel2Data';

const responsiveCarousel1 = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 3
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };
  const responsiveCarousel2 = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

export function PrimerCarousel() {
  return (
    <Row className='row-carousel1'>
     <Carousel className="carousel-especialistas" responsive={responsiveCarousel1} autoPlay={true} autoPlaySpeed={7000} infinite={true} centerMod={false}
      showDots={true} arrows={true} renderButtonGroupOutside={false} >
      {especialistasData.map((especialistas) =>
          <ProCards key = {especialistas.id.toString()}
              id = {especialistas.id} 
              imagen={especialistas.img} 
              nombre ={especialistas.nombre} 
              cargo ={especialistas.cargo} 
              especialidades={especialistas.especialidades}>
          </ProCards>
        )}
      </Carousel>
    </Row>
  );
}
export function SegundoCarousel(){
  return(
    <Row className='row-carousel2 justify-content-md-center'>
    <Carousel className='carousel-servicios' responsive={responsiveCarousel2} autoPlay={true} autoPlaySpeed={7000} infinite={true}
    showDots={true} arrows={true} renderButtonGrou  pOutside={false}>
      {Carousel2Data.map((servicioCarouselItem) =>
        <Row className='row-carousel-servicios-item-panel'>
          <Col className='col-texto-carousel-servicios' sm={7}>
            <h3>
              {servicioCarouselItem.titulo}
            </h3>
            <p>
              {servicioCarouselItem.info}
            </p>
          </Col>
          <Col className='col-img-carousel-servicios' sm={5}>
            <img className='img-carousel-servicios' src={servicioCarouselItem.img}></img>
          </Col>
        </Row>
      )}
    </Carousel>
    </Row>
  )
}