import React from "react"; 
import { useParams } from "react-router-dom";
import { blogData } from "../Data/BlogData";
import { Button, Col, Container, Row} from 'react-bootstrap';
import "./BlogDetail.css"

export function BlogDetail(){
    const params = useParams();
    return(
        <Container className="blog-detail-container" fluid>
            {blogData.map((blogItem) =>
                {if(blogItem.titulo === params.id){
                    return(
                        <Row>
                            <Row className="row-titulo-blog-detail" style={{backgroundImage: `url(${blogItem.imgBanner})`}}>
                                <div className="color-layer-blog-detail">
                                    <p className="titulo-banner-blog-item">{blogItem.titulo}</p>
                                    <p className="fecha-banner-blog-item">{blogItem.fechaPubli}</p>
                                    <p className="creador-banner-blog-item">{blogItem.autor}</p>
                                </div>
                            </Row>
                            <Row className="row-body-blog-detail">
                                <Button className="btn-blog-detail" href="/blog">Volver</Button>
                                <Col className="col-text-blog-detail" md={12}>
                                    <div>
                                        <br></br>
                                    </div>
                                    <br></br>
                                    <p><img className="img-blog-detail" src={blogItem.img}></img>{blogItem.texto}</p>
                                </Col>
                            </Row>
                        </Row>
                    )
                }}    
            )}
            
        </Container>
    )
}