import React from 'react';
import './Header.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Container, Nav, Navbar} from 'react-bootstrap';
import logoBlanco from './images/logoblanco.png'

export function Header(){
    return(
        <div className='Contenedor-Header'>
            <a className="Contenedor-Header"href='/'><img className='Logo-Header' src={logoBlanco} alt='logo-header'></img></a>
        </div>
    )
}
export function Navigation(){
    return(
        <Container fluid style={{padding: "0"}}>
            <Navbar className='Contenedor-Nav justify-content-center' expand="lg" >
                <Navbar.Toggle  className="nav-toggle-control"aria-controls="navbarScroll" />
                <Navbar.Collapse>
                    <Nav className="nav-botones">
                        <Nav.Link className='btn' href='/' >
                            Inicio
                        </Nav.Link>
                        <Nav.Link className='btn' href='/servicios' >
                            Servicios
                        </Nav.Link>
                        <Nav.Link className='btn' href='/profesionales' >
                            Profesionales
                        </Nav.Link>
                        <Nav.Link className='btn' href='/contacto' >
                            Contacto
                        </Nav.Link>
                        <Nav.Link className='btn' href='/blog' >
                            Blog
                        </Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        </Container>
    )
}