import React, { useState, useEffect } from "react";
import { Col, Row, Form, Button, FormControl, FormGroup } from 'react-bootstrap';
import "./Paneles.css"
import { useForm } from 'react-hook-form';
import { fechasDisponiblesData } from "../../Data/EspecialistasFechas";
import { especialistasData } from "../../Data/EspecialistasData";
import dayjs from 'dayjs';
import "dayjs/locale/es";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateCalendar } from '@mui/x-date-pickers';
import TextField from '@mui/material/TextField';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { text } from "@fortawesome/fontawesome-svg-core";
import { border, fontFamily, style } from "@mui/system";

export function SelectorPanel(props) {
    const theme = createTheme({
        components: {
            MuiPickersCalendarHeader:{
                styleOverrides:{
                    label:{
                        fontFamily: "CaviarDreams-Bold",
                        color:"white",
                    }
                }
            },
          MuiPickersDay: {
            styleOverrides: {
              root: {
                backgroundColor: "#f7b0aa",
                color: "white",
                fontFamily: "CaviarDreams-Bold",
                borderRadius: "20%",
                "&:hover": {
                  backgroundColor: "#ff8c7f",
                },
                "&.Mui-selected": {
                  backgroundColor: "#fce7e5 !important",
                  color: "black",
                },
                "&.Mui-disabled": {
                    backgroundColor: "#f7b0aaB3",
                    color: "white",
                    },
              },
              
            }
          },
          MuiDayCalendar:{
            styleOverrides:{
                weekDayLabel:{
                color: "white",
                fontFamily: "CaviarDreams-Bold",
              },

            }
          },
          Mui:{
            styleOverrides:{
                
                selected:{
                backgroundColor:"#fce7e5"
                },
            }
            },
            MuiSvgIcon:{
                styleOverrides:{
                    root:{
                        color:"white"
                    }
                }
            
            }
        }
      });
    const id = props.id

    const [selector, setSelector] = useState(1)
    const [selectedPro, setSelectedPro] = useState(null)
    const [fechaClickeada, setFechaClick] = useState(null);

    const {
        register,
        handleSubmit, getValues,
        formState: { errors },
    } = useForm();

    const onSubmit = handleSubmit((data) => {
        console.log(data)
        if (selector === 1) {
            setSelector(2)
        }
        if (selector === 2) {
            setSelector(3)
        }
        if (selector === 3) {
            alert("Se agendo la hora satisfactoriamente")
        }
        fechasDisponiblesData.map((fechas) => {
            if (fechas.id === id) {
                if (fechas.fecha === getValues("FechaClickeada")) {
                    if (fechas.hora === getValues("Hora")) {
                        fechas.disponible = false
                    }
                }
            }
        })
    })



    const diaMenor = (id) => {
        let menor = dayjs("2200/3/2")
        fechasDisponiblesData.map((fechas) => {
            {
                if (fechas.idEspecialista === id) {
                    if (fechas.fecha.isBefore(menor)) {
                        menor = fechas.fecha
                    }
                }
            }
        })
        return menor
    }

    const diaMayor = (id) => {
        let mayor = dayjs("2000/3/2")
        fechasDisponiblesData.map((fechas) => {
            {
                if (fechas.idEspecialista === id) {
                    if (fechas.fecha.isAfter(mayor)) {
                        mayor = fechas.fecha
                    }
                }
            }
        })
        return mayor
    }
    const isWeekend = (date) => {
        const day = date.day();
        const fecha = fechasDisponiblesData.filter(fechas =>
            fechas.fecha.isSame(date) && fechas.idEspecialista === selectedPro
        )
        return day === 0 || day === 6 || fecha.length === 0;
    }


    useEffect(() => {
        setSelectedPro(id)
        setSelector(1)
        console.log(selectedPro)
    }, [id])

    const panels = () => {
        switch (selector) {
            case 1:
                return (
                    <Row className="contenedor-panel" >
                        <h1 className="titulo-panel">Elige tu hora</h1>
                        <Row className="row-form-group">
                                <Row style={{width:"100%"}}>
                                    <Col className="col-calendario-fechas" sm={12} md={6}>
                                        <FormGroup>
                                            <FormControl as="select" onChange= {(e) => setSelectedPro(parseInt(e.target.value))} value= { selectedPro } required = {true} >
                                                <option value="">Selecciona un especialista</option>
                                                {especialistasData.map((especialistas) => {
                                                    return (
                                                        <option value={especialistas.id}>{especialistas.nombre}</option>
                                                    )
                                                })}

                                            </FormControl>
                                        </FormGroup>
                                        {especialistasData.map((especialistas) => {
                                            if (especialistas.id === selectedPro) {
                                                return (
                                                    <div>
                                                        <br></br>
                                                        <h3>
                                                            {especialistas.nombre}
                                                        </h3>
                                                            <div style={{width:"20% !important"}}>
                                                                <Row>
                                                                    <LocalizationProvider classname="calendario-localization-provider" dateAdapter={AdapterDayjs} adapterLocale="es" style={{width:"100%"}}>
                                                                        <ThemeProvider theme={theme}>
                                                                        <DateCalendar 
                                                                            disablePast={true} 
                                                                            minDate={diaMenor(selectedPro)} 
                                                                            maxDate={diaMayor(selectedPro)} views={['day']} 
                                                                            value={fechaClickeada} 
                                                                            shouldDisableDate={isWeekend} 
                                                                            onChange={(newValue) => setFechaClick(newValue)} 
                                                                            disableHighlightToday={true}
                                                                            />
                                                                        </ThemeProvider>
                                                                    </LocalizationProvider>
                                                                </Row>
                                                            </div>
                                                    </div>
                                                )
                                            }
                                        }
                                        )}
                                    </Col>
                                    <Col className="col-calendario-horas" sm={12} md={6}>
                                        {fechaClickeada === null
                                            ? <div>
                                                {/* <input readonly="readonly" value="" style={{opacity:"0"}}{...register("FechaClickeada", {
                                                    required: {
                                                        value: true
                                                    }})}/> */}
                                            </div>
                                            : <div>
                                                <Form.Control type="text" readonly="readonly" value={dayjs(fechaClickeada).format("DD-MM-YYYY")} {...register("FechaClickeada", {
                                                    required: {
                                                        value: true
                                                    }
                                                })} />
                                                <br />
                                                <Form.Control as="select" {...register("Hora", {
                                                    required: {
                                                        value: true
                                                    },
                                                    minLength: 2
                                                })}>
                                                    <option value={null}></option>
                                                    {fechasDisponiblesData.map((fechas) => {
                                                        if (fechas.idEspecialista === selectedPro) {
                                                            if (fechas.fecha.isSame(fechaClickeada)) {
                                                                // console.log(fechas.fecha)
                                                                if (fechas.disponible) {
                                                                    return (
                                                                        <option value={fechas.hora}>{fechas.hora}</option>
                                                                    )
                                                                }
                                                            }
                                                        }
                                                    })}
                                                </Form.Control>
                                            </div>
                                        }
                                    </Col>
                                </Row>
                        </Row>
                        <Row className="row-buttons">
                            <Col className="contenedor-boton-atras" xs={6}>
                            </Col>
                            <Col className="contenedor-boton-siguiente" xs={6}>
                                <Button className="boton-siguiente" type="submit">Siguiente</Button>
                            </Col>
                        </Row>
                    </Row>
                );

            case 2:
                return (
                    <Row className="contenedor-panel">
                        <h1 className="titulo-panel">Identificacion</h1>
                        <Row className="row-form-group">
                            <Row className="row-form-inputs">
                                <Col className="form-input" md={6}>
                                    <Form.Label>Nombres</Form.Label><br />
                                    <Form.Control type="text" placeholder="Nombres" {...register("Nombres", {
                                        required: {
                                            value: true,
                                            message: "Nombre Requerido"
                                        },
                                        minLength: {
                                            value: 2,
                                            message: "Debe tener al menos 2 caracteres "
                                        },
                                        maxLength: {
                                            maxLength: 50,
                                            message: "Maximo 50 caracteres"
                                        }
                                    })} />
                                    <br />
                                    {
                                        errors.Nombres && <span style={{ color: "red", fontSize: "small" }}>{errors.Nombres.message}</span>
                                    }
                                </Col>
                                <Col className="form-input" md={6}>
                                    <Form.Label>Apellidos</Form.Label><br />
                                    <Form.Control type="text" placeholder="Apellidos" {...register("Apellidos", {
                                        required: {
                                            value: true,
                                            message: "Apellido Requerido"
                                        },
                                        minLength: {
                                            value: 2,
                                            message: "Debe tener al menos 2 caracteres "
                                        },
                                        maxLength: {
                                            maxLength: 50,
                                            message: "Maximo 50 caracteres"
                                        }
                                    })} />
                                    <br />
                                    {
                                        errors.Apellidos && <span style={{ color: "red", fontSize: "small" }}>{errors.Apellidos.message}</span>
                                    }
                                </Col>
                            </Row>
                            <Row className="row-form-inputs">
                                <Col className="form-input" md={6}>
                                    <Form.Label>RUT</Form.Label><br />
                                    <Form.Control type="text" placeholder="RUT" {...register("Rut", {
                                        required: {
                                            value: true,
                                            message: "Apellido Requerido"
                                        },
                                        minLength: {
                                            value: 8,
                                            message: "Debe tener al menos 8 caracteres "
                                        }
                                    })} />
                                    <br />
                                    {
                                        errors.Rut && <span style={{ color: "red", fontSize: "small" }}>{errors.Rut.message}</span>
                                    }
                                </Col>
                                <Col className="form-input" md={6}>
                                    <Form.Label>Telefono</Form.Label><br />
                                    <Form.Control type="text" placeholder="Numero Telefono" {...register("Telefono", {
                                        required: {
                                            value: true,
                                            message: "Telefono es Requerido"
                                        },
                                        minLength: {
                                            value: 9,
                                            message: "Telefono invalido"
                                        }
                                    })} />
                                    <br />
                                    {
                                        errors.Telefono && <span style={{ color: "red", fontSize: "small" }}>{errors.Telefono.message}</span>
                                    }
                                </Col>
                            </Row>
                            <Row className="row-form-inputs">
                                <Col className="form-input" md={6}>
                                    <Form.Label>E-mail</Form.Label><br />
                                    <Form.Control type="email" placeholder="name@example.com" {...register("Email", {
                                        required: {
                                            value: true,
                                            message: "Correo es Requerido"
                                        },
                                        pattern: {
                                            value: /^[a-z0-9._%+-]+@[a-z0-9·-]+\.[a-z]{2,4}$/,
                                            message: "Correo debe ser valido"
                                        }
                                    })} />
                                    <br />
                                    {
                                        errors.Email && <span style={{ color: "red", fontSize: "small" }}>{errors.Email.message}</span>
                                    }
                                </Col>
                            </Row>
                        </Row>
                        <Row className="row-buttons">
                            <Col className="contenedor-boton-atras" xs={6}>
                                <Button className="boton-atras" onClick={() => { setSelector(1) }}>Atras</Button>
                            </Col>
                            <Col className="contenedor-boton-siguiente" xs={6}>
                                <Button className="boton-siguiente" type="submit" onClick={() => { console.log(errors) }}>Siguiente</Button>
                            </Col>
                        </Row>
                    </Row>
                );

            case 3:
                return (
                    <Row className="contenedor-panel">
                        <h1 className="titulo-panel">Confirmación</h1>
                        <Row className="row-form-group">
                            <Col className="col-info-confirmacion" sm={12}>
                                <h3>Nombre: {getValues("Nombres")}</h3>
                                <h3>{getValues("Apellidos")}</h3>
                            </Col>
                            <Col className="col-info-confirmacion" sm={12}>
                                <h3>Rut: {getValues("Rut")}</h3>
                                <h3>. Telefono: {getValues("Telefono")}</h3>

                            </Col>
                            <Col className="col-info-confirmacion" sm={12}>
                                <h3>Email: {getValues("Email")}</h3>
                            </Col>
                            <Col className="col-info-confirmacion" sm={12}>
                                <h3>Especialista: {especialistasData.map((especialistas) => {
                                    if (especialistas.id.toString() === selectedPro.toString() ){
                                        return (
                                            especialistas.nombre
                                        )
                                    }
                                })}</h3>
                            </Col>
                            <Col className="col-info-confirmacion" sm={12}>
                                <h3>Fecha: {getValues("FechaClickeada")}</h3>
                                <h3>. Hora: {getValues("Hora")}</h3>
                            </Col>
                        </Row>
                        <Row className="row-buttons">
                            <Col className="contenedor-boton-atras" xs={6}>
                                <Button className="boton-atras" onClick={() => { setSelector(2) }}>Atras</Button>
                            </Col>
                            <Col className="contenedor-boton-siguiente" xs={6}>
                                <Button className="boton-siguiente" type="submit">Agendar!</Button>
                            </Col>
                        </Row>
                    </Row>
                );
        }
    }
    return (
        <Form className="form-contenedor" onSubmit={onSubmit}>
            <div className="contenedor-botones-superiores">
                {selector === 1
                    ? <button className="boton-superior-selected">1</button>
                    : <button className="boton-superior">1</button>
                }
                {selector === 2
                    ? <button className="boton-superior-selected">2</button>
                    : <button className="boton-superior">2</button>
                }
                {selector === 3
                    ? <button className="boton-superior-selected">3</button>
                    : <button className="boton-superior">3</button>
                }
            </div>
            {panels()}
        </Form>
    )
}