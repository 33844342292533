import React from "react"
import { Col, Container, Row} from 'react-bootstrap';
import bannercontactoimgPc from './images/bannercontacto.png'
import bannercontactoimgMobile from './images/banner-contacto-responsivo.png'
import "./Contacto.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faMobileScreen, faPhone, faLocationDot} from '@fortawesome/free-solid-svg-icons'
import { faSquareFacebook,faLinkedin,faSquareInstagram} from '@fortawesome/free-brands-svg-icons'

export function Contacto(){
    return(
        <Container className="container-contacto" fluid>
            <Row className="row-contacto-banner">
                <img className="img-contacto-banner-pc" src={bannercontactoimgPc} alt="contacto-banner"></img>
                <img className="img-contacto-banner-mobile" src={bannercontactoimgMobile} alt="contacto-banner"></img>
            </Row>
            <Row className="row-contacto-titulo">
                <Col className="col-contacto-titulo">
                    <h1>Contacto</h1>
                </Col>
            </Row>
            <Row className="row-contacto-cuerpo">
                <Col className="col-contacto-cuerpo-contenido" lg={4}>
                    <Row className="row-contacto-cuerpo-contenido">
                        <FontAwesomeIcon icon={faMobileScreen}style={{fontSize: "4rem", color: "#125d51", marginBottom:"5%"}}/>
                        <h5>+56 9 9990 1201</h5>
                    </Row>
                    <Row className="row-contacto-cuerpo-contenido">
                        <FontAwesomeIcon icon={faPhone}style={{fontSize: "4rem", color: "#125d51", marginBottom:"5%"}} />
                        <h5>+56 2 9549 3391</h5>
                    </Row>
                </Col>
                <Col className="col-contacto-cuerpo-contenido" lg={4}>
                    <Row className="row-contacto-cuerpo-contenido">
                        <a className="txt" href="mailto:contacto@maslog.cl" target="_blank" rel="noopener noreferrer" style={{textDecoration:"none", color:"#125d51"}}>
                            <FontAwesomeIcon icon={faEnvelope}style={{fontSize: "4rem", color: "#125d51", marginBottom:"3%"}}/>
                            <h5>contacto@caminemosjuntos.cl</h5>
                        </a>
                    </Row>
                    <Row className="row-contacto-cuerpo-contenido">
                        <a className="txt" href="https://maps.app.goo.gl/jkvxxbXqAxtDrfaC7" target="_blank" rel="noopener noreferrer" style={{textDecoration:"none", color:"#125d51"}}>
                            <FontAwesomeIcon icon={faLocationDot}style={{fontSize: "4rem", color: "#125d51", marginBottom:"3%"}} />
                            <h5>Av. Santa Clara 301 <br/>Huechuraba</h5>
                        </a>
                    </Row>
                </Col>
                <Col className="col-contacto-cuerpo-contenido" lg={4}>
                    <Row className="row-contacto-cuerpo-contenido">
                        <h5>Síguenos en:</h5>
                    </Row>
                    <Row className="row-contacto-cuerpo-contenido">
                        <h1>
                            <a href="https://www.instagram.com/maslog.cl/" target="_blank" rel="noreferrer noopener"><FontAwesomeIcon icon={faSquareInstagram} style={{ fontSize: "4rem", color: "#125d51", margin: "3%" }} /></a>
                            <a href="https://www.linkedin.com/company/maslog-cl/posts/?feedView=all" target="_blank" rel="noreferrer noopener"><FontAwesomeIcon icon={faLinkedin} style={{ fontSize: "4rem", color: "#125d51", margin: "3%" }} /></a>
                            <a href="https://www.facebook.com/people/Maslog/100068013324396/?locale=es_LA" target="_blank" rel="noreferrer noopener"><FontAwesomeIcon icon={faSquareFacebook} style={{ fontSize: "4rem", color: "#125d51", margin: "3%" }} /></a>
                        </h1>
                    </Row>
                </Col>
            </Row>


        </Container>
    )
}