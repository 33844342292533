import React, {useState, useEffect} from "react"
import "./Profesionales.css"
import { Col, Container, Row, Button } from "react-bootstrap"
import { ProCards } from './ProCard';
import { especialistasData } from '../Data/EspecialistasData';
import {SelectorPanel} from './Agenda/Paneles'
export function Profesionales(){
    const agendamientoRef = React.createRef();

    useEffect(() => {
        const hash = window.location.hash;
        if (hash === '#Agendamiento') {
            agendamientoRef.current.scrollIntoView();
        }
    }, []);
    const [idSelected, setIdSelected] = useState(0)
    
    return(
        <Container className="container-profesionales" fluid>
            <Row className="row-titulo">
                <h1>Profesionales</h1>
            </Row>
            <Row className="row-cards-container">
                {especialistasData.map( especialistas => 
                    <Col className="card-container"  md={12} lg={6} xxl={4}>
                        <div className="pro-card" >
                            <ProCards className="card" key = {especialistas.id.toString()}
                                imagen={especialistas.img} nombre ={especialistas.nombre}
                                video={especialistas.video}
                                cargo ={especialistas.cargo} 
                                especialidades={especialistas.especialidades}
                                videourl={especialistas.videourl}
                                minDate={especialistas.fechaInicio}
                                maxDate={especialistas.fechaTermino}
                                id = {especialistas.id}
                                >
                            </ProCards>
                            <Button className="agendar-button" type='button' href='#Agendamiento' onClick={() => setIdSelected(especialistas.id)} >Agendar</Button> 
                        </div>
                    </Col>
                )}
            </Row>
            <Row className="row-control-agendamiento" id="Agendamiento" ref={agendamientoRef}>
                <SelectorPanel id={idSelected} ></SelectorPanel>
            </Row>
        </Container>
    )
}