import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { Home } from './Home/Home';
import { Servicios } from './Servicios/Servicios';
import { Profesionales } from './Profesionales/Profesionales';
import { Contacto } from './Contacto/Contacto';
import { Blog } from './Blog/Blog';
import { blogData } from './Data/BlogData';
import { BlogDetail } from './Blog/BlogDetail';

export const Pages = () => {
    return (
        <Routes>
            <Route path="" element={<Home />} />
            <Route path="servicios" element={<Servicios />} />
            <Route path="profesionales" element={<Profesionales />} />
            <Route path="contacto" element={<Contacto />} />
            <Route path="blog" element={<Blog />}/>
            <Route path= "blog/:id" element={<BlogDetail/>}></Route>
        </Routes>
    )
}