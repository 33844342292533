import React, { useEffect, useRef }from 'react';
import './Servicios.css'
import { Col, Container, Row, Accordion} from 'react-bootstrap';
import imgpsicopedagoga from '../Home/images/psicopedagogo.jpg'
import { serviciosData } from "../Data/ServiciosData";
import bannerServiciosPc from "./images/serviciosbanner.png"
import bannerServiciosMobile from "./images/banner-servicios-responsivo.png"
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import { useLocation, useNavigate } from 'react-router-dom';

function CustomToggle({ children, eventKey }) {
    const decoratedOnClick = useAccordionButton(eventKey, () =>
      console.log('totally custom!'),
    );
  
    return (
      <button
        type="button"
        style={{ backgroundColor: "#125d51",
            borderRadius: "10em",
            borderColor: "#f7b0aa",
            borderWidth: "4px",
            color:"#fce7e5",
            width: "20%",
            height: "50px",
        }}
        onClick={decoratedOnClick}
      >
        {children}
      </button>
    );
  }

export function Servicios(){
    const location = useLocation();

    useEffect(() => {
      const hash = location.hash;
      if (hash) {
        const id = hash.substring(1); // Eliminar el '#'
        const elemento = document.getElementById(id);
        if (elemento) {
          elemento.scrollIntoView();
        }
      }
    }, [location]);
    return(
        <Container className="container-servicios" fluid >
            <Row className="container-banner-servicios">
                <img className="titulo-banner-pc" src={bannerServiciosPc}></img>
                <img className="titulo-banner-mobile" src={bannerServiciosMobile}></img>
            </Row>
            <Row className="container-titulo-servicios">
                <h1 style={{paddingTop:"3%"}}>Servicios</h1>
            </Row>
            <Row className="container-cards-servicios justify-content-md-center">
                {serviciosData.map((servicio) =>
                    {if(servicio.id % 2 === 0){
                        return(
                            <div>
                                <Row id={servicio.id} key={servicio.id} className="card-servicio-pc" md={10} >
                                    <Col className='col-card-texto' md={6} style={{textAlign: "center", paddingLeft:"1%", paddingRight: "0%"}}>
                                        <h3>
                                            {servicio.titulo}
                                        </h3>
                                        <p>
                                            {servicio.descripcion}
                                        </p>
                                    </Col>
                                    <Col className='col-card-imagen' md={6}>
                                        <img className="img-card" src={servicio.img}></img>
                                    </Col>
                                </Row>
                                <Accordion id={servicio.id} className="card-servicio-mobile" > 
                                    <Accordion.Item className='accordion-item' eventKey={servicio.id}>
                                        <Accordion.Header className='accordion-header'>
                                            <Row>
                                            <Col className='col-card-imagen' md={6}>
                                                <img className="img-card" src={servicio.img}></img>
                                            </Col>
                                            <Col className='col-card-texto' md={6} style={{textAlign: "center", paddingLeft:"0%", paddingRight: "1%" ,}}>
                                                <h3>
                                                    {servicio.titulo}
                                                </h3>
                                            </Col>
                                            </Row>
                                        </Accordion.Header>
                                        <Accordion.Body className="accordion-body">
                                            <Col className='col-card-texto' md={12} style={{textAlign: "center", paddingLeft:"0%", paddingRight: "1%" ,}}>
                                                <p>
                                                    {servicio.descripcion}
                                                </p>
                                            </Col>
                                        </Accordion.Body>
                                        <div className="custom-accordion-button">
                                            <CustomToggle eventKey={servicio.id}>Ver Mas!</CustomToggle>
                                        </div>
                                        <br></br>
                                    </Accordion.Item>   
                                </Accordion>
                            </div>
                        )}
                    return(
                        <div>
                            <Row id={servicio.id} key={servicio.id} className="card-servicio-pc" md={10} >
                                <Col className='col-card-imagen' md={6}>
                                    <img className="img-card" src={servicio.img}></img>
                                </Col>
                                <Col className='col-card-texto' md={6} style={{textAlign: "center", paddingLeft:"0%", paddingRight: "1%" ,}}>
                                    <h3>
                                        {servicio.titulo}
                                    </h3>
                                    <p>
                                        {servicio.descripcion}
                                    </p>
                                </Col>
                            </Row>
                            <Accordion id={servicio.id} className="card-servicio-mobile" > 
                                <Accordion.Item className='accordion-item' eventKey={servicio.id}>
                                    <Accordion.Header className='accordion-header'>
                                        <Row>
                                            <Col className='col-card-imagen' md={6}>
                                                <img className="img-card" src={servicio.img}></img>
                                            </Col>
                                            <Col className='col-card-texto' md={6} style={{textAlign: "center", padding:"0% 1% 0% 0%"}}>
                                                <h3>
                                                    {servicio.titulo}
                                                </h3>
                                            </Col>
                                        </Row>
                                    </Accordion.Header>
                                    <Accordion.Body className="accordion-body">
                                        <Col className='col-card-texto' md={12} style={{textAlign: "center", padding:"0% 1% 0% 0%"}}>
                                            <p>
                                                {servicio.descripcion}
                                            </p>
                                        </Col>
                                    </Accordion.Body>
                                    <div className="custom-accordion-button">
                                        <CustomToggle eventKey={servicio.id}>Ver Mas!</CustomToggle>
                                    </div>
                                    <br></br>
                                </Accordion.Item>   
                            </Accordion>
                        </div>
                    )}
                )}
            </Row>
            
        </Container>
    )
}