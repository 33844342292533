import imgpsicopedagoga from '../Home/images/psicopedagogo.jpg'
import imginfantil from '../Home/images/infantil.jpg'
import imgpsicologia from '../Home/images/psicologo.webp'
import imgterapiaocupacional from '../Home/images/terapeuta-ocupacional.jpg'
export const serviciosData = [
    {
        id: 1,
        img: imgpsicologia,
        titulo: "Psicología Clinica",
        descripcion: `La psicología es la ciencia que estudia la mente y el comportamiento humano, así como los procesos mentales subyacentes. En nuestro centro, ofrecemos servicios de psicología que se enfocan en comprender y abordar una variedad de desafíos emocionales, mentales y conductuales que enfrentan las personas en su vida diaria. Nuestro equipo de profesionales altamente capacitados utiliza enfoques terapéuticos basados en la evidencia para ayudar a los pacientes a explorar y entender sus pensamientos, emociones y comportamientos, así como para desarrollar estrategias efectivas de afrontamiento y crecimiento personal.`
    },
    {
        id: 2,
        img: imgpsicopedagoga,
        titulo: "Psicopedagogía",
        descripcion: `La psicopedagogía es una disciplina que se enfoca en comprender y mejorar los procesos de aprendizaje y desarrollo en individuos de todas las edades. Los psicopedagogos utilizan conocimientos de la psicología y la pedagogía para evaluar y apoyar a personas que presentan dificultades en el aprendizaje, tanto en contextos educativos como clínicos. Su trabajo incluye la identificación de barreras para el aprendizaje, el diseño de estrategias de intervención individualizadas y la colaboración con educadores y otros profesionales para optimizar el desarrollo cognitivo, emocional y social de los estudiantes.`
    },
    {
        id: 3,
        img: imgterapiaocupacional,
        titulo: "Terapia Ocupacional",
        descripcion: `La terapia ocupacional es un campo de la salud centrado en ayudar a las personas a participar en las actividades cotidianas que son significativas y funcionales para ellas. En nuestro centro, ofrecemos servicios de terapia ocupacional que se centran en mejorar la independencia y la calidad de vida de nuestros pacientes.`
    },
    {
        id: 4,
        img: imginfantil,
        titulo: "Coaching",
        descripcion: `Nuestro servicio de especialización infantil está diseñado para proporcionar atención especializada a niños y adolescentes en todas las etapas de su desarrollo. Nuestro equipo de profesionales en psicología infantil y psicopedagogía está dedicado a comprender las necesidades únicas de cada niño y a brindar un enfoque de tratamiento personalizado y efectivo, utilizando técnicas terapéuticas adaptadas a la edad y el desarrollo del niño, trabajamos en colaboración con los padres y cuidadores para promover un crecimiento emocional y cognitivo saludable, fortaleciendo el bienestar general del niño y mejorando su calidad de vida.`
    },
    {
        id: 5,
        img: imgterapiaocupacional,
        titulo: "Psicología laboral-organizacional",
        descripcion: `La psicología laboral es una rama especializada de la psicología que se enfoca en el estudio del comportamiento humano en el ámbito laboral y organizacional. Su objetivo principal es mejorar la calidad de vida laboral de los individuos y optimizar el rendimiento de las organizaciones.`
    }
]