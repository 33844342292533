import img1 from "./images/psicologoclinico.jpeg"
export const especialistasData = [
    {
        id: 1,
        img: img1,
        video: true,
        nombre: "Mauricio Lara",
        cargo: "Psicólogo clinico",
        especialidades: "Neurodivergentes - TDHA - Ansiedad- Depresión",
        videourl: "https://www.youtube.com/watch?v=1eZepcg6aC0"
    },
    {
        id: 2,
        img: img1,
        video: false,
        nombre: "Carlos Conejeros",
        cargo: "Psicopedagogo",
        especialidades: "Neurodivergentes - TDHA - Ansiedad- Depresion",
        videourl: ""
    },
    {
        id: 3,
        img: img1,
        video: false,
        nombre: "Jazmine Gatica",
        cargo: "Psicóloga Infantil",
        especialidades: "Neurodivergentes - TDHA - Ansiedad- Depresión",
        videourl: "https://www.youtube.com/watch?v=1eZepcg6aC0"
    },
    {
        id:4,
        img: img1,
        video: true,
        nombre: "Manuel Rosales",
        cargo: "Terapeuta Ocupacional",
        especialidades: "Neurodivergentes - TDHA - Ansiedad- Depresión",
        videourl: "https://www.youtube.com/watch?v=1eZepcg6aC0"
    },
    {
        id:5,
        img: img1,
        video: true,
        nombre: "Raul Risquez",
        cargo: "Psicopedagogo",
        especialidades: "Neurodivergentes - TDHA - Ansiedad- Depresión",
        videourl: "https://www.youtube.com/watch?v=1eZepcg6aC0"
    },
    {
        id:6,
        img: img1,
        video: true,
        nombre: "Francisco Fernández",
        cargo: "Psicologo Clinico",
        especialidades: "Neurodivergentes - TDHA - Ansiedad- Depresión",
        videourl: "https://www.youtube.com/watch?v=1eZepcg6aC0"
    },
    {
        id:7,
        img: img1,
        video: true,
        nombre: "Patricio Fernández",
        cargo: "Psicologo Clinico",
        especialidades: "Neurodivergentes - TDHA - Ansiedad- Depresión",
        videourl: "https://www.youtube.com/watch?v=1eZepcg6aC0"
    },
    {
        id:8,
        img: img1,
        video: false,
        nombre: "Fernando Carrasco",
        cargo: "Terapeuta Ocupacional",
        especialidades: "Neurodivergentes - TDHA - Ansiedad- Depresión",
        videourl: ""
    },
    {
        id:9,
        img: img1,
        video: true,
        nombre: "Arazasú Berasaluce",
        cargo: "Psicóloga Infantil",
        especialidades: "Neurodivergentes - TDHA - Ansiedad- Depresión",
        videourl: "https://www.youtube.com/watch?v=1eZepcg6aC0"
    }
]