import './Card.css';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';

export function ProCards(props) {
    return (
      <a className='card-anchor' href={`profesionales#${props.id}`}>
        <Card className='card-home' style={{backgroundColor: 'rgba(205, 92, 92, 0)', border: '0px', margin: 'auto'}}>
          <Card.Img variant="top" src={props.imagen} />
          <Card.Body>
            <Card.Title className='nombre'>{props.nombre}</Card.Title>
            <Card.Title className='cargo'>{props.cargo}</Card.Title> 
            <Card.Text>
              {props.especialidades}
            </Card.Text>
            {/* <Button variant="primary">Agendar</Button> */}
          </Card.Body>
        </Card>
      </a>
    );
  }