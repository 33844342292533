import dayjs from "dayjs"
export const fechasDisponiblesData= [
    {
        idEspecialista: 1,
        fecha: dayjs("2024-5-1"),
        hora: "12:30",
        disponible: true
    },
    {
        idEspecialista: 1,
        fecha: dayjs("2024-5-1"),
        hora: "13:30",
        disponible: false
    },
    {
        idEspecialista: 1,
        fecha: dayjs("2024-5-1"),
        hora: "14:30",
        disponible: true
    },
    {
        idEspecialista: 1,
        fecha: dayjs("2024-5-2"),
        hora: "15:30",
        disponible: true
    },
    {
        idEspecialista: 1,
        fecha: dayjs("2024-5-4"),
        hora: "13:30",
        disponible: true
    }
    ,
    {
        idEspecialista: 2,
        fecha: dayjs("2024-5-1"),
        hora: "13:30",
        disponible: true
    }
    ,
    {
        idEspecialista: 2,
        fecha: dayjs("2024-5-2"),
        hora: "13:30",
        disponible: true
    }
    ,
    {
        idEspecialista: 2,
        fecha: dayjs("2024-5-3"),
        hora: "13:30",
        disponible: true
    }
    ,
    {
        idEspecialista: 3,
        fecha: dayjs("2024-5-1"),
        hora: "13:30",
        disponible: true
    }
    ,
    {
        idEspecialista: 3,
        fecha: dayjs("2024-5-2"),
        hora: "13:30",
        disponible: true
    }
    ,
    {
        idEspecialista: 3,
        fecha: dayjs("2024-5-3"),
        hora: "13:30",
        disponible: true
    }
    ,
    {
        idEspecialista: 4,
        fecha: dayjs("2024-5-1"),
        hora: "13:30",
        disponible: true
    }
    ,
    {
        idEspecialista: 4,
        fecha: dayjs("2024-5-2"),
        hora: "13:30",
        disponible: true
    }
    ,
    {
        idEspecialista: 4,
        fecha: dayjs("2024-5-3"),
        hora: "13:30",
        disponible: true
    }
    ,
    {
        idEspecialista: 5,
        fecha: dayjs("2024-5-1"),
        hora: "13:30",
        disponible: true
    }
    ,
    {
        idEspecialista: 5,
        fecha: dayjs("2024-5-2"),
        hora: "13:30",
        disponible: true
    }
    ,
    {
        idEspecialista: 5,
        fecha: dayjs("2024-5-3"),
        hora: "13:30",
        disponible: true
    }
    ,
    {
        idEspecialista: 6,
        fecha: dayjs("2024-5-1"),
        hora: "13:30",
        disponible: true
    }
    ,
    {
        idEspecialista: 6,
        fecha: dayjs("2024-5-2"),
        hora: "13:30",
        disponible: true
    }
    ,
    {
        idEspecialista: 6,
        fecha: dayjs("2024-5-3"),
        hora: "13:30",
        disponible: true
    }
    ,
    {
        idEspecialista: 7,
        fecha: dayjs("2024-5-1"),
        hora: "13:30",
        disponible: true
    }
    ,
    {
        idEspecialista: 7,
        fecha: dayjs("2024-5-2"),
        hora: "13:30",
        disponible: true
    }
    ,
    {
        idEspecialista: 7,
        fecha: dayjs("2024-5-3"),
        hora: "13:30",
        disponible: true
    }
    ,
    {
        idEspecialista: 8,
        fecha: dayjs("2024-5-1"),
        hora: "13:30",
        disponible: true
    }
    ,
    {
        idEspecialista: 8,
        fecha: dayjs("2024-5-2"),
        hora: "13:30",
        disponible: true
    }
    ,
    {
        idEspecialista: 8,
        fecha: dayjs("2024-5-3"),
        hora: "13:30",
        disponible: true
    }
    ,
    {
        idEspecialista: 9,
        fecha: dayjs("2024-5-1"),
        hora: "13:30",
        disponible: true
    }
    ,
    {
        idEspecialista: 9,
        fecha: dayjs("2024-5-2"),
        hora: "13:30",
        disponible: true
    }
    ,
    {
        idEspecialista: 9,
        fecha: dayjs("2024-5-3"),
        hora: "13:30",
        disponible: true
    }

]