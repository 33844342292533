import { Modal } from 'react-bootstrap';
import './ProCard.css';
import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import ReactPlayer from 'react-player'
import { especialistasData } from '../Data/EspecialistasData';

export function ProCards(props) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const refs = especialistasData.reduce((acc, value) => {
    acc[value.id] = React.createRef();
    return acc;
}, {});
useEffect(() => {
  const hash = window.location.hash;
  if (hash) {
      const id = parseInt(hash.substring(1)); // Eliminar el '#'
      if (refs[id] && refs[id].current) {
          refs[id].current.scrollIntoView();
      }
  }
}, []);
  return(
    <Card style={{backgroundColor: 'rgba(205, 92, 92, 0)', border: '0px', margin: 'auto'}} ref={refs[props.id]}>
      {props.video
        ? 
          <button className='boton-imagen' onClick={handleShow}>
            <Card.Img className='image' variant="top" src={props.imagen}/>
          </button>
        :
          <button className='boton-imagen' style={{borderWidth:"0"}}>
          <Card.Img className='image' variant="top" src={props.imagen} />
          </button>
      }
      <Card.Body className='body'>
        <Card.Title className='nombre'>{props.nombre}</Card.Title>
        <Card.Title className='cargo'>{props.cargo}</Card.Title> 
        <Card.Text>
          {props.especialidades}
        </Card.Text>
      </Card.Body>
      <Modal className='modal-video' show={show} onHide={handleClose}>
        <ReactPlayer url={props.videourl} playing={true}/>
      </Modal>
    </Card>
  )
}