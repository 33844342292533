import React from "react";
import ReactDOM from 'react-dom'
import './Home.css';
import '../Fonts/Fonts.css'
import banner1pc from './images/banner-inicio-web.png'
import banner1mobile from './images/banner-inicio-2.png'
import { Col, Container, Row, Accordion} from 'react-bootstrap';
import { PrimerCarousel, SegundoCarousel } from './Carousel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons'
import cargaLaboralPdf from "../Descargables/Infografia-carga-laboral.pdf"
import autoestimaSaludablePdf from "../Descargables/Infografia-autoestima-saludable.pdf"
import manejoEstresPdf from "../Descargables/Guia-manejo-del-estres.pdf"
import manejoRabietasPdf from "../Descargables/Guia-manejo-de-rabietas.pdf"
import { useNavigate } from 'react-router-dom';


export function Home(){
    const navigate = useNavigate();
    const handleClick = (id) => {
        navigate(`/servicios#${id}`);
      };
    return(
        <Container className="Home-Container" fluid>
            <div className="Contenedor-Banner1">
                <img className='Home-Banner1-pc' src={banner1pc} alt='banner'></img>
                <img className='Home-Banner1-mobile' src={banner1mobile} alt='banner'></img>
            </div>
            <Row className="Row-container-servicios">
                <Row className='Row1 justify-content-md-center'>
                    <Col className="Col-Titulo-Servicios">
                        <h2>Nuestros servicios se extienden por diversas áreas, incluyendo: </h2>
                    </Col>
                </Row>
                <Row className='Row2 justify-content-md-center' lg={10}>
                    <Col className='Col-Servicios justify-content-md-center' md={12} lg={6}>
                        <a className="a-servicios" onClick={()=> handleClick(1)} style={{textDecoration:"none" }}>
                            <div className='Forma1'>
                                <div className="color-layer1">
                                    <h2 className='Forma-Texto'>Psicología clínica</h2>
                                    <h2 className="Forma-Texto-Hover">Ver Mas</h2>
                                </div>
                            </div>
                        </a>
                    </Col>
                    <Col className='Col-Servicios justify-content-md-center' lg={6}>
                        <a className="a-servicios" onClick={()=> handleClick(2)}  style={{textDecoration:"none" }}>
                            <div className='Forma2'>
                                <div className="color-layer2">
                                    <h2 className='Forma-Texto'>Psicopedagogía</h2>
                                    <h2 className="Forma-Texto-Hover">Ver Más</h2>
                                </div>
                            </div>
                        </a>
                    </Col>
                </Row>
                <Row className='Row2 justify-content-md-center' lg={10}>
                <Col className='Col-Servicios justify-content-md-center' lg={6}>
                    <a className="a-servicios" onClick={()=> handleClick(3)}  style={{textDecoration:"none" }}>
                        <div className='Forma3'>
                            <div className="color-layer3">
                                <h2 className='Forma-Texto'>Terapia ocupacional</h2>
                                <h2 className="Forma-Texto-Hover">Ver Más</h2>
                            </div>
                        </div>
                    </a>
                    </Col>
                    <Col className='Col-Servicios justify-content-md-center' lg={6}>
                        <a className="a-servicios" onClick={()=> handleClick(4)}  style={{textDecoration:"none" }}>
                            <div className='Forma4'>
                                <div className="color-layer4">
                                    <h2 className='Forma-Texto'>Coaching</h2>
                                    <h2 className="Forma-Texto-Hover">Ver Más</h2>
                                </div>
                            </div>
                        </a>
                    </Col>
                </Row>
                <Row className='Row2 justify-content-md-center' lg={10}>
                    <Col className='Col-Servicios justify-content-md-center' lg={6}>
                        <a className="a-servicios" onClick={()=> handleClick(5)} style={{textDecoration:"none" }}>
                            <div className='Forma5'>
                                <div className="color-layer5">
                                    <h2 className='Forma-Texto'>Psicología laboral-organizacional</h2>
                                    <h2 className="Forma-Texto-Hover">Ver Más</h2>
                                </div>
                            </div>
                        </a>
                    </Col>
                </Row>
            </Row>
            <PrimerCarousel></PrimerCarousel>
            <SegundoCarousel></SegundoCarousel>
            <Row className="row-preguntasydocumentos justify-content-md-center" id="Preguntas" lg={12}>
                <Col className="col-preguntas" lg={6}>
                        <Accordion className="accordion-container">
                            <div className="item-container">
                            <Accordion.Item className="pregunta-container" eventKey="0">
                                <Accordion.Header className="titulo-pregunta">¿Qué Terapias Ofrecen?</Accordion.Header>
                                <Accordion.Body className="respuesta-pregunta">
                                En nuestro centro psicológico, ofrecemos una amplia gama de terapias para satisfacer las necesidades 
                                individuales de nuestros pacientes. Desde terapias especializadas diseñadas para tratar trastornos 
                                específicos hasta ayuda psicológica general para aquellos que buscan comprenderse mejor a sí mismos 
                                y mejorar su bienestar emocional.
                                </Accordion.Body>
                            </Accordion.Item>
                            </div>
                            <div className="item-container">
                            <Accordion.Item className="pregunta-container" eventKey="1">
                                <Accordion.Header className="titulo-pregunta">¿Como funcionan las Terapias?</Accordion.Header>
                                <Accordion.Body className="respuesta-pregunta">
                                Reconocemos que cada individuo es único, por lo que nuestras terapias se adaptan a las necesidades 
                                específicas de cada paciente. Creemos en la importancia de una atención personalizada y centrada en 
                                el cliente, donde se desarrollen estrategias terapéuticas que se ajusten a sus circunstancias 
                                individuales y metas de tratamiento.
                                </Accordion.Body>
                            </Accordion.Item>
                            </div>
                            <div className="item-container">
                            <Accordion.Item className="pregunta-container" eventKey="2">
                                <Accordion.Header className="titulo-pregunta">¿Modalidad Online o Presencial?</Accordion.Header>
                                <Accordion.Body className="respuesta-pregunta">
                                Actualmente, nuestra modalidad de atención se realiza de manera presencial, ofreciendo un espacio 
                                seguro y acogedor para la terapia. Sin embargo, estamos trabajando diligentemente para implementar 
                                opciones adicionales para nuestros pacientes. Próximamente, tendrán la oportunidad de elegir entre 
                                sesiones presenciales u online, brindando flexibilidad y comodidad para adaptarse a las necesidades 
                                individuales y preferencias de cada uno.
                                </Accordion.Body>
                            </Accordion.Item>
                            </div>
                            <div className="item-container">
                            <Accordion.Item className="pregunta-container" eventKey="3">
                                <Accordion.Header className="titulo-pregunta">¿Cuál es el valor de una sesión?</Accordion.Header>
                                <Accordion.Body className="respuesta-pregunta">
                                Los costos de nuestras terapias varían según el tipo de tratamiento que necesite y el profesional 
                                con el que decida trabajar. Entendemos la importancia de que la terapia sea accesible para todos, 
                                por lo que ofrecemos una gama de opciones tarifarias para adaptarse a diversas circunstancias financieras.
                                </Accordion.Body>
                            </Accordion.Item>
                            </div>
                        </Accordion>
                </Col>
                <Col className="col-documentos" lg={6}> 
                    <div className="container-documentos">
                        <Row className="container-titulo">
                            <h1>
                                Descargables
                            </h1>
                        </Row>
                        <Row className="row-container-descargables">
                            <Row className="row-container-descargable-item">
                                <Col md={3}>
                                    <a href={manejoRabietasPdf} target="_blank">
                                        <FontAwesomeIcon icon={faFilePdf} style={{color: "#125d51",fontSize: "3rem"}} />
                                    </a>
                                </Col>
                                <Col md={9}>
                                    <a href={manejoRabietasPdf} target="_blank">
                                        <h2>Manejo de rabietas</h2>
                                    </a>
                                </Col>
                            </Row>
                            <Row className="row-container-descargable-item">
                                <Col md={3}>
                                    <a href={manejoEstresPdf} target="_blank">
                                        <FontAwesomeIcon icon={faFilePdf} style={{color: "#125d51", fontSize: "3rem"}} />
                                    </a>
                                </Col>
                                <Col md={9}>
                                    <a href={manejoEstresPdf} target="_blank">
                                        <h2>Manejo de estrés</h2>
                                    </a>
                                </Col>
                            </Row>
                            <Row className="row-container-descargable-item">
                                <Col md={3}>
                                    <a href={autoestimaSaludablePdf} target="_blank">
                                        <FontAwesomeIcon icon={faFilePdf} style={{color: "#125d51", fontSize: "3rem"}} /> 
                                    </a>
                                </Col>
                                <Col md={9}>
                                    <a href={autoestimaSaludablePdf} target="_blank">
                                        <h2>Autoestima saludable en la adolecencia</h2>
                                    </a>
                                </Col>
                            </Row>
                            <Row className="row-container-descargable-item">
                                    <Col md={3}>
                                        <a href={cargaLaboralPdf} target="_blank">
                                            <FontAwesomeIcon icon={faFilePdf} style={{color: "#125d51", fontSize: "3rem"}} /> 
                                        </a>
                                    </Col>
                                    <Col md={9}>
                                        <a href={cargaLaboralPdf} target="_blank">
                                            <h2>Manejo del tiempo y carga laboral</h2>
                                        </a>
                                    </Col>
                            </Row>
                        </Row>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}