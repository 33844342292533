import ataqueDePanicoImg from "./images/ataquedepanico.png"
import familiaImg from "./images/familia.jpg"
import maternidadImg from "./images/maternidad.jpg"
import mujerMayorImg from "./images/mujermayor.jpg"
import niñoTristeImg from "./images/niñotriste.jpg"
import problemaParejaImg from "./images/problemapareja.jpg"
import ataqueDePanicoBanner from "./images/ataquedepanicoBanner.png"
import familiaBanner from "./images/familiaBanner.jpg"
import maternidadBanner from "./images/maternidadBanner.jpg"
import mujerMayorBanner from "./images/mujermayorBanner.jpg"
import niñoTristeBanner from "./images/niñotristeBanner.jpg"
import problemaParejaBanner from "./images/problemaparejaBanner.jpg"

export const blogData = [
    {
        id: 1,
        img: ataqueDePanicoImg,
        imgBanner:ataqueDePanicoBanner,
        titulo: "Como aliviar los síntomas de un ataque de pánico",
        fechaPubli: "20-03-2024",
        autor:"Mauricio Lara",
        descripcion:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec porttitor tempor leo. Praesent leo mauris, congue suscipit consectetur at, tristique quis nibh. Aliquam ut gravida enim, non egestas augue.",
        texto: `Los ataques de ansiedad pueden ser abrumadores, pero es importante recordar que hay estrategias que pueden ayudarte a aliviar los síntomas y sentirte más tranquilo. Aquí tienes algunas técnicas efectivas que puedes probar la próxima vez que te enfrentes a un ataque de ansiedad:
        Practica la respiración profunda: La respiración profunda puede ayudar a reducir la frecuencia cardíaca y calmar el sistema nervioso. Intenta inhalar profundamente por la nariz, contando hasta cuatro, y luego exhala lentamente por la boca, también contando hasta cuatro. Repite este proceso varias veces hasta que sientas que tu cuerpo se relaja.
        
        Usa afirmaciones positivas: Las afirmaciones positivas pueden ayudar a cambiar tus pensamientos y reducir la sensación de pánico durante un ataque de ansiedad. Repite frases como "Estoy seguro", "Puedo manejar esto" o "Esto pasará" para tranquilizarte a ti mismo y aumentar tu confianza.
        
        Practica la atención plena: La atención plena implica enfocarse en el momento presente y aceptar tus pensamientos y sentimientos sin juzgarlos. Durante un ataque de ansiedad, intenta enfocarte en tus sentidos, como el sonido de tu respiración o la sensación de tus pies en el suelo. Esto puede ayudar a disminuir la intensidad de los síntomas y a mantenerte en el presente.
        
        Utiliza técnicas de relajación muscular: La tensión muscular puede aumentar la sensación de ansiedad, por lo que relajar tu cuerpo puede ayudar a reducir los síntomas. Prueba tensar y relajar cada grupo muscular, comenzando por los pies y trabajando hacia arriba hasta llegar a la cabeza.
        
        Distrae tu mente: Busca una actividad que te guste y que te ayude a distraer tu mente de los pensamientos ansiosos. Puede ser leer un libro, escuchar música relajante, dibujar o salir a caminar al aire libre. La distracción puede ayudarte a cambiar tu enfoque y a reducir la intensidad de los síntomas de ansiedad.
        
        Busca apoyo: No tengas miedo de pedir ayuda cuando la necesites. Habla con un amigo, un familiar o un profesional de la salud mental sobre lo que estás experimentando. El apoyo emocional puede marcar la diferencia y ayudarte a sentirte menos solo durante un ataque de ansiedad.
        `
    },
    {
        id: 2,
        img: mujerMayorImg,
        imgBanner:mujerMayorBanner,
        titulo: "El temor a la muerte",
        fechaPubli: "20-03-2024",
        autor:"Mauricio Lara",
        descripcion:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec porttitor tempor leo. Praesent leo mauris, congue suscipit consectetur at, tristique quis nibh. Aliquam ut gravida enim, non egestas augue.",
        texto: `A medida que envejecemos, es común que el tema de la muerte se vuelva más prominente en nuestras vidas. Para muchos adultos mayores, el miedo a la muerte puede convertirse en una preocupación persistente que afecta su bienestar emocional y su calidad de vida. Este miedo puede surgir de diversas fuentes, como el temor a lo desconocido, la pérdida de control o el sufrimiento físico. Además, las experiencias de pérdida de seres queridos y la confrontación con la propia mortalidad pueden intensificar estos sentimientos.

        Explorar tus creencias y valores en torno a la muerte es un paso importante para enfrentar este miedo. Reflexionar sobre tus creencias personales sobre la muerte y el significado de la vida puede ayudarte a encontrar una mayor claridad y aceptación. Discutir estos temas con amigos de confianza, líderes espirituales o profesionales de la salud mental puede proporcionar un espacio seguro para explorar tus pensamientos y emociones.

        Además, encontrar sentido y propósito en la vida diaria puede ayudar a reducir el miedo a la muerte. Participar en actividades que te apasionen, establecer metas realistas y cultivar conexiones significativas con amigos y seres queridos puede brindarte una sensación de satisfacción y plenitud. Encontrar un propósito en la vida puede ayudarte a enfocarte en lo que realmente importa y a encontrar mayor paz interior.

        Practicar la aceptación y el mindfulness también puede ser beneficioso para superar el miedo a la muerte. Aprender a aceptar la realidad de la muerte como parte natural del ciclo de la vida puede ayudarte a encontrar una mayor tranquilidad y serenidad. La práctica del mindfulness puede ayudarte a estar presente en el momento presente y a desarrollar una actitud de aceptación hacia tus pensamientos y emociones, incluido el miedo a la muerte.

        Hablar abiertamente sobre tus preocupaciones y miedos con amigos, familiares o profesionales de la salud también puede proporcionar un alivio emocional. Compartir tus sentimientos puede ayudarte a sentirte comprendido y apoyado, y puede fortalecer tus conexiones con los demás. Además, buscar ayuda profesional de un terapeuta o consejero especializado en el tema puede proporcionarte un espacio seguro para explorar tus sentimientos y encontrar estrategias efectivas para gestionar el miedo a la muerte.
        `
    },
    {
        id: 3,
        img: familiaImg,
        imgBanner:familiaBanner,
        titulo: "Rehabilitación y su influencia en la autoestima",
        fechaPubli: "20-03-2024",
        autor:"Mauricio Lara",
        descripcion:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec porttitor tempor leo. Praesent leo mauris, congue suscipit consectetur at, tristique quis nibh. Aliquam ut gravida enim, non egestas augue." , 
        texto: `La rehabilitación es un proceso integral que tiene como objetivo ayudar a las personas a recuperar o mejorar su función física, emocional o cognitiva después de una lesión, enfermedad o discapacidad. Uno de los aspectos menos conocidos pero igualmente importantes de la rehabilitación es su influencia en la autoestima de los individuos que participan en ella. La autoestima se refiere a la percepción y valoración que una persona tiene de sí misma, y puede verse afectada por una variedad de factores, incluidos los desafíos físicos o emocionales que surgen de una lesión o enfermedad.

        La participación en programas de rehabilitación puede tener un impacto significativo en la autoestima de una persona de varias maneras. En primer lugar, el proceso de rehabilitación puede ayudar a restaurar la función física y la movilidad, lo que a su vez puede mejorar la independencia y la autoconfianza. Cuando una persona es capaz de realizar tareas que anteriormente le resultaban difíciles o imposibles debido a una lesión o discapacidad, puede experimentar un aumento en su sentido de competencia y eficacia personal, lo que contribuye positivamente a su autoestima.

        Además, la rehabilitación proporciona un entorno de apoyo y empoderamiento que puede ser fundamental para el desarrollo de una autoestima saludable. Los profesionales de la rehabilitación, como fisioterapeutas, terapeutas ocupacionales y psicólogos clínicos, trabajan en estrecha colaboración con los pacientes para establecer metas realistas y proporcionar el apoyo necesario para alcanzarlas. Este enfoque centrado en el cliente promueve la autonomía y la autoeficacia, lo que puede fortalecer la confianza en uno mismo y la percepción positiva de uno mismo.

        Además de los beneficios físicos y emocionales directos, la rehabilitación también puede ayudar a las personas a reconstruir su identidad y sentido de sí mismas después de una lesión o enfermedad. La participación en actividades terapéuticas y la superación de desafíos pueden proporcionar un sentido de logro y una sensación de propósito que contribuyen positivamente a la autoestima. Además, el apoyo social y la interacción con otros individuos que están pasando por experiencias similares pueden ayudar a reducir el aislamiento y la sensación de alienación que a menudo acompañan a la discapacidad o enfermedad.
        `
    },
    {
        id: 4,
        img: maternidadImg,
        imgBanner:maternidadBanner,
        titulo: "Maternidad y psicologia",
        fechaPubli: "20-03-2024",
        autor:"Mauricio Lara",
        descripcion:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec porttitor tempor leo. Praesent leo mauris, congue suscipit consectetur at, tristique quis nibh. Aliquam ut gravida enim, non egestas augue." ,
        texto:`La maternidad y la psicología están estrechamente vinculadas, ya que la psicología aborda una amplia gama de aspectos emocionales, cognitivos y conductuales relacionados con la experiencia de ser madre y criar a un hijo. Desde el momento en que una mujer descubre que está embarazada hasta el proceso de crianza y desarrollo del niño, la psicología proporciona una comprensión profunda de los desafíos, las alegrías y las complejidades involucradas en el viaje de la maternidad.

        Durante el embarazo, la psicología perinatal se centra en comprender y apoyar el bienestar emocional de la madre, así como en abordar los cambios físicos y hormonales que pueden influir en su estado de ánimo y en su relación con el futuro bebé. Se exploran temas como el estrés prenatal, la ansiedad, la depresión y la adaptación a los cambios en el cuerpo y en la vida cotidiana.

        Una vez que el bebé nace, la psicología infantil y del desarrollo se convierte en un aspecto esencial de la maternidad. Ayuda a las madres a comprender las necesidades emocionales, cognitivas y sociales de sus hijos en cada etapa del desarrollo, desde la infancia hasta la adolescencia. Esto incluye aprender a establecer vínculos seguros con el bebé, fomentar la autoestima y la autoeficacia en el niño, y comprender cómo las experiencias tempranas afectan el desarrollo futuro.

        La psicología también juega un papel importante en la crianza de los hijos al proporcionar estrategias efectivas para abordar los desafíos comunes, como la disciplina, la comunicación, la resolución de conflictos y la promoción de un ambiente familiar saludable y de apoyo. Además, ayuda a las madres a navegar por temas más complejos, como la ansiedad parental, la culpa, el agotamiento y el equilibrio entre la maternidad y otras responsabilidades.
        `
    },
    {
        id: 5,
        img: niñoTristeImg,
        imgBanner:niñoTristeBanner,
        titulo: "Formas de expresarse con tus hijos",
        fechaPubli: "20-03-2024",
        autor:"Mauricio Lara",
        descripcion:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec porttitor tempor leo. Praesent leo mauris, congue suscipit consectetur at, tristique quis nibh. Aliquam ut gravida enim, non egestas augue." ,  
        texto:`La relación entre padres e hijos es una de las conexiones más significativas y duraderas en la vida de una persona. En el centro de esta relación se encuentra la comunicación, un elemento fundamental que moldea la dinámica familiar, influye en el desarrollo emocional de los niños y establece las bases para una conexión duradera y significativa entre padres e hijos. La forma en que nos expresamos como padres con nuestros hijos tiene un impacto profundo en su autoestima, confianza y habilidades de afrontamiento, y puede influir en su bienestar emocional a lo largo de sus vidas.

        En primer lugar, la comunicación abierta y honesta es esencial para fomentar un ambiente familiar saludable y de apoyo. Cuando los padres se comunican de manera clara y respetuosa con sus hijos, se establece un canal de diálogo que les permite expresar sus pensamientos, sentimientos y preocupaciones libremente. Esto promueve la confianza mutua y fortalece el vínculo emocional entre padres e hijos, creando un espacio seguro donde los niños se sienten valorados y escuchados.

        Además, la comunicación efectiva entre padres e hijos es fundamental para enseñar habilidades importantes de resolución de problemas y manejo del conflicto. Cuando los padres modelan una comunicación abierta y constructiva, están proporcionando a sus hijos un ejemplo poderoso de cómo abordar los desafíos de manera saludable y productiva. Esto les ayuda a desarrollar habilidades de comunicación efectivas y les enseña la importancia de expresarse de manera respetuosa y empática en todas las relaciones.

        La forma en que nos expresamos como padres también puede tener un impacto significativo en la autoestima y la autoconfianza de nuestros hijos. El elogio sincero y el reconocimiento de los logros de los niños refuerzan su sentido de valía personal y les ayudan a desarrollar una autoimagen positiva. Por otro lado, las críticas constantes o el lenguaje negativo pueden socavar la confianza de los niños en sí mismos y minar su motivación para enfrentar nuevos desafíos.
        `
    },
    {
        id: 6,
        img: problemaParejaImg,
        imgBanner:problemaParejaBanner,
        titulo: "La privacidad en las relaciones",
        fechaPubli: "20-03-2024",
        autor:"Mauricio Lara",
        descripcion:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec porttitor tempor leo. Praesent leo mauris, congue suscipit consectetur at, tristique quis nibh. Aliquam ut gravida enim, non egestas augue." ,   
        texto:`En el complejo tejido de las relaciones de pareja, la privacidad emerge como un elemento vital que no solo moldea la dinámica entre los individuos, sino que también establece los cimientos para un vínculo saludable y duradero. La noción de privacidad en este contexto se refiere a la preservación del espacio personal, emocional y físico de cada miembro de la pareja. Este espacio no solo permite la expresión auténtica de la identidad individual, sino que también promueve la confianza mutua, la autonomía y el respeto dentro de la relación.

        En primer lugar, la privacidad en las relaciones de pareja se asienta sobre el respeto individual. Es el reconocimiento de que cada persona en la pareja es un individuo único, con sus propios intereses, necesidades y límites. Al respetar la privacidad de la pareja, se honra su autonomía y se evita cualquier forma de invasión o control que pueda socavar su sentido de identidad y autoestima.

        La confianza, otro pilar fundamental en cualquier relación, se ve intrínsecamente vinculada a la privacidad. Cuando se respeta el espacio personal y emocional de la pareja, se cultiva un ambiente de seguridad y apertura que fomenta la confianza mutua. Los miembros de la pareja saben que pueden ser ellos mismos sin temor a ser juzgados o invadidos, lo que fortalece el lazo emocional y promueve una conexión más profunda y significativa.

        Además, la privacidad ofrece a cada individuo la oportunidad de crecer y desarrollarse personalmente dentro de la relación. Al tener espacio para explorar intereses individuales, perseguir metas personales y desarrollar habilidades, se enriquece la vida de ambos miembros de la pareja. Este crecimiento personal no solo beneficia a los individuos, sino que también enriquece la relación a través del intercambio de experiencias y aprendizajes.

        Por otro lado, la falta de privacidad puede tener consecuencias perjudiciales en la relación. La invasión del espacio personal puede generar resentimiento y frustración en la pareja, minando la armonía y la satisfacción en la relación. Además, puede erosionar la confianza mutua, creando un clima de desconfianza y sospecha que socava la estabilidad emocional y el bienestar de ambos miembros.
        
        En última instancia, la privacidad en las relaciones de pareja es un aspecto esencial que contribuye al bienestar emocional y la salud de la relación. Al respetar el espacio personal y emocional de cada individuo, se establecen las bases para una conexión más profunda, una confianza más sólida y un crecimiento personal mutuo. Por lo tanto, es fundamental encontrar un equilibrio entre la intimidad compartida y el respeto por la privacidad individual para mantener una relación saludable y satisfactoria.
        `
    }
]