import './App.css';
import { BrowserRouter as Router } from 'react-router-dom';
import {Header,Navigation} from './Header/Header'
import {Footer} from './Footer/Footer'
import { Pages } from './Pages';
import { BotonFlotante } from './BotonFlotante';

function App() {
  return (
    <div className='App'>
      <Router>
        <Header></Header>
        <Navigation></Navigation>
        <Pages />
        <Footer></Footer>
        <BotonFlotante className="boton-flotante-parent"></BotonFlotante>
      </Router>
    </div>
  );
}
export default App;
