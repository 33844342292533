import React from "react";
import './Footer.css'
import {Col, Row, Container} from 'react-bootstrap';
import logo from '../Header/images/logoblanco.png'
import { faSquareFacebook,faLinkedin,faSquareInstagram} from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


export function Footer(){
    return(
        <Container className="footer-container" fluid>
            <Row lg={12}>
                <Col className="logo-footer-container " sm={3}>
                    <img className="logo-footer" src={logo} alt="logo-footer"></img>
                </Col>
                <Col className="direccion-footer" sm={3} >
                    <a className="txt" href="https://maps.app.goo.gl/jkvxxbXqAxtDrfaC7" target="_blank" rel="noopener noreferrer" style={{textDecoration:"none", color:"white"}}>
                        Huechuraba <br/>
                        Av.Santa Clara 2805 of.301<br/>
                        +56 9 2423 2346
                    </a>
                </Col>
                <Col className="nav-footer-container" sm={2}>
                    <a href="/" style={{color: "#fce7e5", textDecoration: "none"}}>Inicio</a><br/>
                    <a href="/servicios" style={{color: "#fce7e5", textDecoration: "none"}}>Servicios</a><br/>
                    <a href="/especialistas" style={{color: "#fce7e5", textDecoration: "none"}}>Especialistas</a><br/>
                    <a href="/contacto" style={{color: "#fce7e5", textDecoration: "none"}}>Contacto</a><br/>
                    <a href="/blog" style={{color: "#fce7e5", textDecoration: "none"}}>Blog</a><br/>
                </Col>
                <Col className="redes-footer" sm={2}>
                    <Row className="row-titulo-redes-footer">
                        <h5>Síguenos en:</h5>
                    </Row>
                    <Row className="row-iconos-redes-footer">
                        <h1><a href="https://www.instagram.com/maslog.cl/" target="_blank" rel="noreferrer noopener" style={{color: "#fce7e5"}}><FontAwesomeIcon icon={faSquareInstagram}style={{fontSize: "2rem", margin: "0.5rem"}} /></a> 
                        <a href="https://www.linkedin.com/company/maslog-cl/posts/?feedView=all" target="_blank" rel="noreferrer noopener" style={{color: "#fce7e5"}}><FontAwesomeIcon icon={faLinkedin}style={{fontSize: "2rem",margin: "0.5rem"}} /></a>
                        <a href="https://www.facebook.com/people/Maslog/100068013324396/?locale=es_LA" target="_blank" rel="noreferrer noopener" style={{color: "#fce7e5"}}><FontAwesomeIcon icon={faSquareFacebook}style={{fontSize: "2rem", margin: "0.5rem"}} /></a></h1>
                    </Row>
                </Col>
                <Col sm={2}>
                </Col>
            </Row>
        </Container>
    )
}