import "./Blog.css"
import React from "react"
import { Col, Container, Row, Accordion} from 'react-bootstrap';
import { blogData } from "../Data/BlogData";
import bannerBlogPc from "./Images/blogBanner.png"
import bannerBlogMobile from "./Images/banner-blog-responsivo.png"


export function Blog(){
    return(
        <Container className="blog-page-container" fluid>
            <Row className="row-blog-banner-titulo">
                <img className="blog-banner-pc" src={bannerBlogPc}></img>
                <img className="blog-banner-mobile" src={bannerBlogMobile}></img>
            </Row>
            <Row className="row-blog-page-titulo">
                <h1>Blogs</h1>
            </Row>
            <Row className="row-blog-items-container" >
                {blogData.map( blogItem =>
                    <Col className="col-blog-item-card " sm={10} md={9}lg={5} xl={5} xxl={3} >
                        <a href={"blog/" + blogItem.titulo}>
                            <div className="div-color-layer">
                                <h2 className="color-layer-hover-text">
                                    Ver más
                                </h2>
                                <div className="div-img-blog-item-card" lg={12}>
                                    <img className="img-blog-item-card" src={blogItem.img} alt="Imagen"/>
                                </div>
                                <div className="body-blog-item-card" lg={12}>
                                    <h4 lg={12}>
                                        {blogItem.titulo}
                                    </h4>
                                    <h6>
                                        {blogItem.fechaPubli}
                                    </h6>
                                    <p>
                                        {blogItem.texto.substring(0, 200)}<strong><a className="ver-mas"> . . .</a></strong>
                                    </p>
                                </div>
                            </div>
                        </a>
                    </Col>
                )}
            </Row>
        </Container>
    )
} 