import img1 from "./images/AnsiedadCarousel.png"
import img2 from "./images/DepresiónCarousel.jpg"
import img3 from "./images/autistaCarousel.jpg"
import img4 from "./images/SueñoCarousel.jpg"
export const Carousel2Data = [
    {
        id: 1,
        img: img1,
        titulo: "¿Qué es la Ansiedad?",
        info: `La ansiedad es una respuesta natural ante el estrés, pero puede volverse abrumadora. En nuestra página de psicología, ofrecemos recursos para comprender y manejar la ansiedad, ayudando a recuperar el equilibrio emocional y mejorar la calidad de vida.`
    },
    {
        id: 2,
        img: img2,
        titulo:"¿Qué es la depresión y cuáles son sus síntomas más característicos?",
        info: `La depresión es un trastorno del estado de ánimo que causa sentimientos persistentes de tristeza, pérdida de interés en actividades cotidianas, cambios en el apetito y el sueño, fatiga y dificultad para concentrarse. Si experimentas estos síntomas de manera persistente, es importante buscar ayuda profesional.`
    },
    {
        id: 3,
        img: img3,
        titulo:"Trastorno del espectro austista",
        info: `El trastorno del espectro autista (TEA) es un término amplio que abarca una variedad de condiciones neurológicas caracterizadas por déficits en la comunicación social y patrones de comportamiento repetitivos o restrictivos. Dentro del espectro autista hay diferentes niveles de gravedad y una amplia gama de síntomas que pueden manifestarse de manera diferente en cada individuo.`
    },
    {
        id: 4,
        img: img4,
        titulo:"Trastornos del sueño",
        info: `Los trastornos del sueño abarcan una variedad de condiciones que afectan la calidad, la duración y el patrón del sueño. Algunos ejemplos incluyen el insomnio, caracterizado por dificultades para conciliar el sueño o permanecer dormido; la apnea del sueño, que implica interrupciones en la respiración durante el sueño; y la narcolepsia, que se manifiesta con ataques repentinos de sueño durante el día. Estos trastornos pueden tener un impacto significativo en la salud y el bienestar general, y es importante buscar ayuda profesional si se experimentan problemas persistentes con el sueño.`
    }
]